import Swal from 'sweetalert2'

class customFunctions {

    constructor() {
        this.redirectByCookie();
        this.openSmarticoByCookie();
        this.actionSmarticoPostMessages();
        const addCSS = css => document.head.appendChild(document.createElement("style")).innerHTML=css;
        addCSS(".swal2-container{z-index: 10000000;}");
        window.addEventListener("load", (event) => {
            this.intervalHadDoneHisCommitment = false;
            wzCustomFunctions.showProviderGames();
            wzCustomFunctions.createImageLazyLoader();
            wzCustomFunctions.executeXpLoginEvent();
            setTimeout(() => {
                wzCustomFunctions.anchorPage();
            }, 1000);
        });
    }

    actionSmarticoPostMessages() {
        window.addEventListener(
            "message",
            (event) => {
                if(event.origin === 'https://libs.smartico.ai' && typeof event.data !== 'undefined') {
                    let jsonData = JSON.parse(event.data);
                    if(jsonData && jsonData.dp) {
                        switch (jsonData.dp) {
                            case 'cashbackRequest':
                                this.sendWithdrawal();
                                break;
                        }
                    }
                }
            },
            false,
        );
    }

    anchorPage() {
        let hash = window.location.hash.substring(1);
        let urlSearchParams = new URLSearchParams(window.location.search);
        let anchor;
        if(hash && !document.getElementById(hash)) {
             anchor = hash;
        } else if(urlSearchParams.has('scrollto')){
             anchor = urlSearchParams.get('scrollto');
        }
        if(anchor){
            let anchorElement = document.getElementById(anchor) ||
                    document.getElementsByName(anchor)[0] ||
                    document.getElementsByClassName(anchor)[0];
            anchorElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    checkVisible(element) {
        if (element) {
            const rect = element.getBoundingClientRect();
            const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
            return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
        }
        return false;
    }

    WzIntersectionObserver(elementSelector, callbackwz, disconect=true) {
        const options = {
            root: null, // Use the viewport as the container
            rootMargin: '0px',
            threshold: 0.1 // Trigger when 10% of the target is visible
        };

        const observer = new IntersectionObserver(callbackwz, options);
        const elements = document.querySelectorAll(elementSelector);
        elements.forEach(element => {
            observer.observe(element);
            if(disconect){
                observer.disconnect();
            }
        })
    }

    sendWithdrawal() {
        let cashbackButton = document.querySelector('[data-cashback]');
        let claimedButtonText = 'Claimed';
        let processingButtonText = 'Processing';
        let claimedButtonArray = [];
        let ProcessingButtonArray = [];
        ProcessingButtonArray['EN'] = 'Processing';
        ProcessingButtonArray['FI'] = 'Käsittelyssä';
        ProcessingButtonArray['ES'] = 'Procesando';
        ProcessingButtonArray['NO'] = 'Behandling';
        ProcessingButtonArray['FR'] = 'En cours';
        ProcessingButtonArray['PT'] = 'Processamento';
        if(basicInfo) {
            processingButtonText = ProcessingButtonArray[basicInfo.lang];
        }
        if(cashbackButton) {
            if (cashbackButton.classList.contains('disabled')) return '';
            cashbackButton.innerHTML = processingButtonText;
            cashbackButton.classList.add('disabled');
        }
        $.ajax({
            method:'POST',
            url: '/ajax/ajaxhandler.php?class=rest&method=sendWithdrawl',
        }).done((data, status) => {
            if(data.status == 200) {
                let options = {
                    title: data.message.description,
                    allowOutsideClick: false,
                    confirmButtonText: '<i class="fa fa-thumbs-o-up"></i>',
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    },
                };
                if(data.message.title && data.message.description) {
                    options.title = data.message.title;
                    options.html = data.message.description;
                }
                Swal.fire(options);
            }
        });
    }

    closeSweetAlert() {
        Swal.close();
    }

    executeXpLoginEvent() {
        let url = new URL(window.location.href);
        let query = url.search
        if (query.includes('?loggedin')) {
            if(typeof xtremepush !== 'undefined') {
                xtremepush('event', 'user.login');
            }
        }
    }


    showProviderGames() {
        const providerName =  getCookie('providerName');
        let specialBrands = ['DV', 'GI', 'HK', 'JV'];
        if(providerName && basicInfo && specialBrands.includes(basicInfo.brandshort)) {
            document.querySelector('.game-categories [data-cat="popular"]')?.classList.remove('selected');
            let categoryItem = document.querySelector('.game-categories [data-cat="provider"],.game-categories[data-cat="providers"],.categories__item[data-cat="providers"],.mobile-menu-item[data-cat="providers"]');
            categoryItem?.classList.add('categories__item');
            categoryItem?.classList.add('selected');
            categoryItem?.classList.add('active');
        }
        const selectedCageory = document.querySelector('.categories__item.selected')?.getAttribute('data-category') || document.querySelector('.categories__item.selected')?.getAttribute('data-cat')|| document.querySelector('.categories__item.active')?.getAttribute('data-cat')|| document.querySelector('.mobile-menu-item.active')?.getAttribute('data-cat');
        if(providerName && ['provider', 'providers'].includes(selectedCageory?.toLowerCase())) {
            deleteCookie('providerName');
            const gameRendererBrands = ['DV'];
            const wzGameRendererBrands = ['JV', 'HK', 'VT', 'SP', 'PT', 'GI'];
            const wzNewGameBrands = ['LC'];
            if(basicInfo?.brandshort) {
                if(gameRendererBrands.includes(basicInfo.brandshort)) {
                    let intervalProviderGames= setInterval(() => {
                        gameRenderer.searchGame(providerName.toUpperCase(), true);
                        if(document.getElementById('games-count').childNodes.length === 0 && document.getElementById('games-container').childNodes.length > 0){
                            document.getElementById('games-container').scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
                            clearInterval(intervalProviderGames);
                        }

                    }, 1000);
                    setTimeout(() => {clearInterval(intervalProviderGames)}, 7000);
                } else if(wzGameRendererBrands.includes(basicInfo.brandshort)) {
                    let newDiv = document.createElement("div");
                    newDiv.setAttribute('data-code', providerName.toLowerCase());
                    document.getElementById('games-container')?.setAttribute('data-cat', 'providers');
                    let intervalProviderGames= setInterval(() => {
                        wzGameRender['views']['games-container']?.load_provider_games(newDiv);
                        if(wzGameRender['views']['games-container']?.load_provider_games){
                            document.getElementById('games-container').scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
                            clearInterval(intervalProviderGames);
                        }
                    }, 1000);
                    setTimeout(() => {clearInterval(intervalProviderGames)}, 7000);
                } else if(wzNewGameBrands.includes(basicInfo.brandshort)) {
                    wzNewGameTech.setGamesTilesOfProvider(providerName);
                }
            }
        }
    }

    redirectToProviderGames(providerName) {
        const doNothingBrands = ['BZ', 'CL'];
        const goHomeBrands = ['DV'];
        const goHomeBrandsCustom = ['GI'];
        if(basicInfo?.brandshort && doNothingBrands.includes(basicInfo.brandshort)) {
            window.location.href=siteUrls.provider+'?select='+providerName;
        } else if (basicInfo?.brandshort && goHomeBrands.includes(basicInfo.brandshort)) {
            setCookie('providerName', providerName);
            window.location.href='/';
        } else if (basicInfo?.brandshort && goHomeBrandsCustom.includes(basicInfo.brandshort)) {
            setCookie('providerName', providerName);
            window.location.href='en-gb/games/providers';
        } else {
            setCookie('providerName', providerName);
            window.location.href=siteUrls.provider.replace('[dynamic]', providerName);
        }
    }

    createImageLazyLoader() {
        function handleIntersection(entries) {
            entries.map((entry) => {
                if (entry.isIntersecting) {
                    // Item has crossed our observation
                    // threshold - load src from data-src
                    if(entry.target.tagName.toLowerCase() === 'div') {
                        entry.target.style.backgroundImage = "url('"+entry.target.dataset.src+"')";
                        if (typeof GAMES_IMAGE_FORMAT_FALLBACK !== "undefined") {
                            for (const format in GAMES_IMAGE_FORMAT_FALLBACK) {
                                const fallbackFormat = GAMES_IMAGE_FORMAT_FALLBACK[format];
                                if (entry.target.dataset.src.endsWith("."+format)) {
                                    entry.target.style.backgroundImage += ",url('"+entry.target.dataset.src.replace("."+format, "."+fallbackFormat).replace('notfound-','')+"')";
                                }
                            }
                        }
                    } else {
                        entry.target.src = entry.target.dataset.src;
                    }
                    entry.target.classList.remove('lazy-loader');
                    // Job done for this item - no need to watch it!
                    observer.unobserve(entry.target);
                }
            });
        }

        const images = document.querySelectorAll('.lazy-loader');
        const observer = new IntersectionObserver(handleIntersection);
        images.forEach(image => observer.observe(image));
    }

    redirectByCookie() {
        const urlSearchParams  = new URLSearchParams(window.location.search);
        if(urlSearchParams.has('showmp') || document.body.getAttribute('data-route-id') === "marketingPrefs") return;
        let redirectUrl = getCookie('redirectToWz');
        if(redirectUrl) {
            deleteCookie('redirectToWz');
            window.location.href = redirectUrl;
        }
    }

    openSmarticoByCookie() {
        setTimeout(() => {
            if (document.body.getAttribute('data-logged-in') == "1") {
                let SmarticoCall = getCookie('wzSmarticoOpen');
                if(SmarticoCall) {
                    _smartico.dp('dp:' + SmarticoCall);
                    deleteCookie('wzSmarticoOpen');
                }
            }
        }, "1000");
    }

    setRedirectCookie(redirectUrl) {
        setCookie('redirectToWz', redirectUrl);
    }
}

var wzCustomFunctions = new customFunctions();
window.wzCustomFunctions = wzCustomFunctions;


function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function deleteCookie(name) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970;path=/';
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

// Defined at the end on purpose, otherwise claims function not defined
// Expected site had defined GAMES_IMAGE_FORMAT_FALLBACK as JS constant alongside use this
// function in their template in onerror, like onerror="imageFallbackHandler(this,'/path/to/image.jpg')"
function imageFallbackHandler(_this, imagePath){
    _this.onerror = null; // To prevent potential infinite loops
    const dotExtensionPos = imagePath.lastIndexOf('.');
    const extension = imagePath.slice(dotExtensionPos + 1);
    const fallbackExtension = GAMES_IMAGE_FORMAT_FALLBACK[extension];
    if (!!fallbackExtension) {
        const newSrc = imagePath.substr(0, dotExtensionPos) + '.' + fallbackExtension;
        _this.src = newSrc;
    }
}
